@tailwind base;
@tailwind components;
@tailwind utilities;




:root {
  --brand-primary: #17BEBB;
  --brand-secondary: #2E282A;
  --brand-alt: #CD5334;
  --brand-alt-2: #EDB88B;


  --colour-palette-1: hsla(0, 0%, 100%, 0.9);
  --colour-palette-2: hsla(0, 0%, 80%, 0.9);
  --colour-palette-3: hsla(0, 0%, 60%, 0.9);
  --colour-palette-4: hsla(0, 0%, 40%, 0.9);
  --colour-palette-5: hsla(0, 0%, 20%, 0.9);

}
